.header{
    background-image: linear-gradient(#00365f,#033961);
    float: left;
    left: 0;
    padding: 40px 10%;
    top: 0;
    width: 100%;
}
.header .willimg{
    float: left;
    height: 69px;
    margin-block: 10px;
    top: 0;
    width: 301px;
    z-index: 100;
}

.header-weact{
    background-color: white;
    float: left;
    left: 0;
    padding: 25px 10%;
    top: 0;
    width: 100%;
}
.responsive-flex {
    display: flex;
}

.header .weactimg{
    float: left;
    /* height: 69px; */
    margin-block: 10px;
    top: 0;
    /* width: 301px; */
    z-index: 100;
}
.rightimage{
    color: #fff;
    display: flex;
    float: right;
    font-family: Montserrat,sans-serif;
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    margin-block: 10px;
    z-index: 25;
    
}
.rightimage img{
    height: 75px;
    padding-right: 10px;
}
@media (max-width: 990px) {
    .header .willimg{
        width:auto;
        height: 50px;
    }
    .rightimage{
        font-size: 20px;
    }
    .rightimage img{
        height: 50px!important;
        width: auto;
    }
    .responsive-flex {
        flex-direction: column !important;
    }
}
@media (max-width: 544px){
    .header{
        display:grid;
        flex-direction: column !important;
    }
}