.single-submit-button{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top:10px;
}


.button1.success {
    background-color: #398E3B;
    border-radius: 10px;
    box-shadow: 0 7px 11px rgb(0 0 0 / 50%);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 650;
    /* margin-bottom: 67px; */
    padding: 10px 30px;
    color: #fff !important;
    border: none;
    border-width: 1.5px;
}

.button1.success:hover{
    color: #fff;
    background-color: black;
    border-color: black;
}

.success {
    color: #398E3B !important;
    font-family: Montserrat,sans-serif;
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 40px;
}

.button1{
    background-color: #ec9a00;
    border-radius: 10px;
    box-shadow: 0 7px 11px rgb(0 0 0 / 50%);
    font-family: Montserrat,sans-serif;
    font-size: 20px;
    font-weight: 650;
    /* margin-bottom: 67px; */
    padding: 10px 30px;
    color: #fff;
    border-color: #0d6efd;
    border-width: 1.5px;
}
.button1:hover{
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

@media (max-width: 900px) {
    .button1{
        width: 100%;
        font-size: 16px;
    }
}