.footer{
    /* background-image: linear-gradient(#00365f,#033961); */
    background-color: #3375b8;
    color: aliceblue;
    float: left;
    padding: 40px 10%;
    position: relative;
    width: 100%;
    font-size: 18px;
}
.footer h5{
    font-size: 21px !important;
}
.footer img{
    height: 60px;
    float: right;
}
.footer .copyright{
    float: right;
    font-size: 16px;
}

.footer.weact{
    /* background-image: linear-gradient(#00365f,#033961); */
    background-color: white;
    color: black;
    float: left;
    font-family: Arial, Helvetica, sans-serif;
    padding: 40px 10%;
    position: relative;
    width: 100%;
    font-size: 18px;
    top: -5px;
    z-index: 9999;
}
.footer.weact h5{
    font-size: 21px !important;
    font-family: Arial, Helvetica, sans-serif;
}
.footer.weact img{
    height: 116px;
    float: right;
}
.footer.weact .copyright{
    float: right;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
}
@media (max-width: 549px){
    
    .footer{
        display:grid;
    }

    .footer.weact {
        display:grid;
    }
}
